import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';

import { append as svgAppend, create as svgCreate } from 'tiny-svg';

export default function CustomRender(eventBus) {
  BaseRenderer.call(this, eventBus, 1500);

  this.getShapePath = BaseRenderer.prototype.getShapePath;
  this.drawConnection = BaseRenderer.prototype.drawConnection;
  this.getConnectionPath = BaseRenderer.prototype.getConnectionPath;

  this.canRender = function(element) {
    if (element.url) {
      return true;
    } else return false;
  };

  this.drawShape = async function(parent, shape) {

    var gfx = svgCreate('image', {
      x: 0,
      y: 0,
      width: shape.width,
      height: shape.height,
      href: shape.url,
    });

    svgAppend(parent, gfx);
    return gfx;
  };
}

CustomRender.$inject = [ 'eventBus' ];
