export default async function(event, modeler, jsonData) {
  var eventBus = modeler.get('eventBus');

  var shape = event.element;

  const modeling = modeler.get('modeling');

  Object.values(jsonData.palette).forEach((palette_element) => {
    if (palette_element.type === shape.type) {
      if (
        !jsonData.elements.some((json_element) => json_element.id === shape.id)
      ) {
        Object.keys(palette_element.events).forEach((eventType) => {
          const eventFunction = new Function(
            'e',
            'modeling',
            'diagramElement',
            `
            (async () => {
              if (${palette_element.events[eventType] !== ''}) {
                e.preventDefault(); 
                let element = e.element;
                function updateText(text) {
                    modeling.updateLabel(diagramElement, text);
                }
                function updateImage(image) {
                    e.element.url = image;
                }
                if (e.element.id === "${shape.id}") { 
                    ${palette_element.events[eventType]};
                    modeling.updateProperties(e.element, {});
                } 
              }
            })();
            `,
          );
          eventBus.on(
            eventType,
            1000,
            async (e) => eventFunction(e, modeling, shape),
            { passive: true },
          );
        });
      } else {
        const element = jsonData.elements.find(
          (json_element) => json_element.id === shape.id,
        );
        Object.keys(element.events).forEach((eventType) => {
          const eventFunction = new Function(
            'e',
            'modeling',
            'diagramElement',
            `
                (async () => {
                  if (${element.events[eventType] !== ''}) {
                    e.preventDefault(); 
                    let element = e.element;
                    function updateText(text) {
                        modeling.updateLabel(diagramElement, text);
                    }
                    function updateImage(image) {
                        e.element.url = image;
                    }
                    if (e.element.id === "${element.id}") { 
                        ${element.events[eventType]}; 
                        modeling.updateProperties(e.element, {});
                    } 
                  }
                })();
                `,
          );
          eventBus.on(
            eventType,
            900,
            async (e) => eventFunction(e, modeling, shape),
            { passive: true },
          );
        });
      }
    }
  });

  /*   if (shape.type === 'label') {
    const id = shape.id.split('_')[0] + '_' + shape.id.split('_')[1];
    data.elements.find((element) => element.id === id).text =
      shape.businessObject.name;
    return;
  }

  const newElementData = {
    type: shape.type,
    id: shape.id,
    fill: null,
    stroke: null,
    text: null,
    events: {},
  };

  // Adiciona o novo elemento ao array de elementos em data
  if (!data.elements) {
    data.elements = [];
  }
  data.elements.push(newElementData); */
}
