import inherits from 'inherits-browser';

import PaletteProvider from 'bpmn-js/lib/features/palette/PaletteProvider';

import get from '../importer/index.js';

export default function CustomPaletteProvider(
  palette,
  create,
  elementFactory,
  spaceTool,
  lassoTool,
  handTool,
  globalConnect,
  translate,
) {
  PaletteProvider.call(
    this,
    palette,
    create,
    elementFactory,
    spaceTool,
    lassoTool,
    handTool,
    globalConnect,
    translate,
  );

  this._create = create;
  this._elementFactory = elementFactory;
  this.translate = translate;
}

inherits(CustomPaletteProvider, PaletteProvider);

const data = await get;

CustomPaletteProvider.$inject = [
  'palette',
  'create',
  'elementFactory',
  'spaceTool',
  'lassoTool',
  'handTool',
  'globalConnect',
  'translate',
];

CustomPaletteProvider.prototype.getPaletteEntries = function (element) {
  var actions = PaletteProvider.prototype.getPaletteEntries.call(this, element);
  var create = this._create;
  var elementFactory = this._elementFactory;

  function createAction(type, group, title, url, initialHeight, initialWidth, options = {}) {
    function createListener(event) {
      var shape = elementFactory.createShape(
        Object.assign({ type: type, url: url, width: initialWidth, height: initialHeight, init: true }, options),
      );
      create.start(event, shape);
    }

    return {
      group: group,
      className: title,
      title: title,
      action: {
        dragstart: createListener,
        click: createListener,
      },
    };
  }

  data.images.forEach((element) => {
    actions[`create.custom:${element.title}`] = createAction(
      'bpmn:IntermediateThrowEvent',
      element.group,
      element.title,
      element.url,
      element.initialHeight || 100,
      element.initialWidth || 100,
    );
  });

  actions['create.link'] = createAction(
    'bpmn:DataObjectReference',
    'event',
    'Link Event',
    'https://cdns.iconmonstr.com/wp-content/releases/preview/2018/240/iconmonstr-link-thin.png',
    36,
    36,
    { link: true }  
  );

  return actions;
};
