/* eslint-disable indent */
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-js.css';

import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';

import './style.css';

import $, { event } from 'jquery';

import colorPicker from './color-picker/index.js';
import BpmnModeler from 'bpmn-js/lib/Modeler';

import diagramXML from '../resources/newDiagram.bpmn';

import ResizeAllRules from './resize-all-rules/index.js';
import CustomPalette from './palette-provider/index.js';
import CustomRender from './draw/index.js';
import get from './importer/index.js';

import shapeAdded from './utils/shapeAdded.js';
import applyJsonModifications from './utils/applyJsonModifications.js';
import ElementRegistry from 'diagram-js/lib/core/ElementRegistry.js';
import { create } from 'diagram-js/lib/model/index.js';

var jsonData = {};

var container = $('#js-drop-zone');

var modeler = new BpmnModeler({
  container: '#js-canvas',
  keyboard: {
    bindTo: window,
  },
  additionalModules: [ CustomPalette, ResizeAllRules, CustomRender, colorPicker ],

  /* bpmnRenderer: {
    defaultFillColor: '#1f1f1f',
    defaultStrokeColor: '#fff',
  }, */
});

var eventBus = modeler.get('eventBus');

eventBus.on('shape.added', async (event) => {
  jsonData = await get;
  shapeAdded(event, modeler, jsonData);
});

function createNewDiagram() {
  openDiagram(diagramXML);
}

async function openDiagram(xml) {
  try {
    await modeler.importXML(xml);

    container.removeClass('with-error').addClass('with-diagram');

    jsonData = await get;

    applyJsonModifications(modeler, eventBus, jsonData);
  } catch (err) {
    container.removeClass('with-diagram').addClass('with-error');

    container.find('.error pre').text(err.message);

    console.error(err);
  }
}

function registerFileDrop(container, callback) {
  function handleFileSelect(e) {
    e.stopPropagation();
    e.preventDefault();

    var files = e.dataTransfer.files;

    var file = files[0];

    var reader = new FileReader();

    reader.onload = function(e) {
      var xml = e.target.result;

      callback(xml);
    };

    reader.readAsText(file);
  }

  function handleDragOver(e) {
    e.stopPropagation();
    e.preventDefault();

    e.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
  }

  container.get(0).addEventListener('dragover', handleDragOver, false);
  container.get(0).addEventListener('drop', handleFileSelect, false);
}

document
  .getElementById('js-import-diagram')
  .addEventListener('click', function() {
    const fileInput = document.getElementById('js-file-input');
    fileInput.click(); // Abre a janela para escolher o arquivo

    fileInput.addEventListener(
      'change',
      function(event) {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = function(e) {
            const xml = e.target.result;
            openDiagram(xml); // Chama a função para importar o diagrama
          };
          reader.readAsText(file);
        } else {
          alert('Por favor, selecione um arquivo BPMN válido.');
        }
      },
      { once: true },
    ); // Garante que o evento seja acionado apenas uma vez
  });

// file drag / drop ///////////////////////

// check file api availability
if (!window.FileList || !window.FileReader) {
  window.alert(
    'Looks like you use an older browser that does not support drag and drop. ' +
      'Try using Chrome, Firefox or the Internet Explorer > 10.',
  );
} else {
  registerFileDrop(container, openDiagram);
}

// bootstrap diagram functions

/* $(function() {
  $('#js-create-diagram').click(function(e) {
    e.stopPropagation();
    e.preventDefault();

    createNewDiagram();
  });

  var downloadLink = $('#js-download-diagram');
  var downloadSvgLink = $('#js-download-svg');

  $('.buttons a').click(function(e) {
    if (!$(this).is('.active')) {
      e.preventDefault();
      e.stopPropagation();
    }
  });

  function setEncoded(link, name, data) {
    var encodedData = encodeURIComponent(data);

    if (data) {
      link.addClass('active').attr({
        href: 'data:application/bpmn20-xml;charset=UTF-8,' + encodedData,
        download: name,
      });
    } else {
      link.removeClass('active');
    }
  }

  var exportArtifacts = debounce(async function() {
    try {
      const { svg } = await modeler.saveSVG();

      setEncoded(downloadSvgLink, 'diagram.svg', svg);
    } catch (err) {
      console.error('Error happened saving svg: ', err);
      setEncoded(downloadSvgLink, 'diagram.svg', null);
    }

    try {
      const { xml } = await modeler.saveXML({ format: true });
      setEncoded(downloadLink, 'diagram.bpmn', xml);
    } catch (err) {
      console.error('Error happened saving XML: ', err);
      setEncoded(downloadLink, 'diagram.bpmn', null);
    }
  }, 500);

  modeler.on('commandStack.changed', exportArtifacts);
}); */

createNewDiagram();

// helpers //////////////////////

function debounce(fn, timeout) {
  var timer;

  return function() {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(fn, timeout);
  };
}

const palette = document.querySelector('.djs-palette');

console.log(palette);

var zoomScroll = modeler.get('zoomScroll');

palette.addEventListener('mouseover', (e) => {
  zoomScroll.toggle(false);
});

palette.addEventListener('mouseout', (e) => {
  zoomScroll.toggle(true);
});

function assignFunctionToElement(modeler, elementId, eventType, func) {
  const elementRegistry = modeler.get('elementRegistry');
  const eventBus = modeler.get('eventBus');
  const target_element = elementRegistry.get(elementId);
  const modeling = modeler.get('modeling');

  if (!target_element) {
    console.error(`Elemento com ID ${elementId} não encontrado.`);
    return;
  }

  function eventFunction(e, modeling, diagram_element) {
    e.preventDefault();
    let element = e.element;
    function updateText(text) {
      modeling.updateLabel(element, text);
    }
    function updateImage(image) {
      e.element.url = image;
    }
    if (e.element.id === diagram_element.id) {
      func();
      modeling.updateProperties(e.element, {});
    }
  }


  // Adiciona a função ao elemento
  eventBus.on(
    eventType,
    900,
    async (e) => eventFunction(e, modeling, target_element),
    { passive: true },
  );

  console.log(`Função atribuída ao elemento com ID ${elementId}.`);
}

window.modeler = modeler;

window.assignFunctionToElement = assignFunctionToElement;



let isEditMode = true;

function toggleMode() {
  isEditMode = !isEditMode;

  const palette = document.querySelector('.djs-palette');
  const resizableElements = document.querySelector('.layer-resizers');
  const editableElements = document.querySelectorAll('.djs-editable');
  const elements = document.querySelectorAll('.djs-hit')
  const pad = document.querySelector('.djs-context-pad-parent');
  const dragging = modeler.get('dragging');

  if (isEditMode) {
    // Ativar modo de edição
    console.log('Modo de edição ativado');
    if (palette) palette.style.display = 'block';
    resizableElements.style.display = 'block';
    editableElements.forEach(el => el.contentEditable = 'true');
    elements.forEach(el => el.style.cursor = 'move');
    pad.style.display = 'block';
    dragging.setOptions({ manual: false });

  } else {
    // Ativar modo de visualização
    console.log('Modo de visualização ativado');
    if (palette) palette.style.display = 'none';
    resizableElements.style.display = 'none';
    editableElements.forEach(el => el.contentEditable = 'false');
    elements.forEach(el => el.style.cursor = 'default');
    pad.style.display = 'none';

    dragging.setOptions({ manual: true });
  }
}

document.getElementById('toggle-mode').addEventListener('click', toggleMode);