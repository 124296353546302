import { is } from 'bpmn-js/lib/util/ModelUtil';

/**
 * A basic color picker implementation.
 *
 * @param {EventBus} eventBus
 * @param {ContextPad} contextPad
 * @param {CommandStack} commandStack
 */
export default function ColorPicker(eventBus, contextPad, commandStack) {
  contextPad.registerProvider(this);

  commandStack.registerHandler('shape.updateColor', UpdateColorHandler);

  function changeColor(event, element) {

    // Lista de cores para escolha
    const colors = [
      '#fff',
      '#f9939f',
      '#9bf09d',
      '#92d2f9',
      '#f8ed62',
      '#ffbfd3',
    ];

    const borders = [
      '#000',
      '#f21a35',
      '#19a337',
      '#005b96',
      '#e9d700',
      '#ff00a9',
    ];

    // Cria um container para os seletores de cor
    const colorPickerContainer = document.createElement('div');
    colorPickerContainer.style.position = 'absolute';
    colorPickerContainer.style.top = (event.clientY - 20) + 'px';
    colorPickerContainer.style.left = (event.clientX + 20) + 'px';
    colorPickerContainer.style.display = 'flex';
    colorPickerContainer.style.flexDirection = 'row';
    colorPickerContainer.style.backgroundColor = '#f1f2f3';
    colorPickerContainer.style.border = '1px solid #ccc';
    colorPickerContainer.style.borderRadius = '5px';
    colorPickerContainer.style.zIndex = '1000';

    colors.forEach((color, index) => {
      const colorOption = document.createElement('div');
      colorOption.style.backgroundColor = color;
      colorOption.style.width = '20px';
      colorOption.style.height = '20px';
      colorOption.style.margin = '5px';
      colorOption.style.cursor = 'pointer';
      colorOption.style.borderRadius = '5px';
      colorOption.style.border = `1px solid ${borders[index]}`;

      colorOption.addEventListener('click', () => {
        commandStack.execute('shape.updateColor', {
          element: element,
          color: color,
          strokeColor: borders[index],
        });

        document.body.removeChild(colorPickerContainer);
        colorPickerContainer.removeEventListener(
          'mousedown',
          handleOutsideClick,
        );
      });

      colorPickerContainer.appendChild(colorOption);
    });

    function handleOutsideClick(e) {
      if (
        document.body.contains(colorPickerContainer) &&
        !colorPickerContainer.contains(e.target)
      ) {
        document.body.removeChild(colorPickerContainer);
        document.removeEventListener('mousedown', handleOutsideClick);
      }
    }

    // Adiciona o ouvinte de evento ao document
    document.addEventListener('mousedown', handleOutsideClick);

    document.body.appendChild(colorPickerContainer);

    colorPickerContainer.focus();
  }

  this.getContextPadEntries = function(element) {
    return {
      changeColor: {
        group: 'edit',
        className: 'entry bpmn-icon-color',
        html: '<div><?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --><svg fill="#000000" viewBox="0 0 32 32" id="icon" xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1{fill:none;}</style></defs><title>color palette</title><circle cx="10" cy="12" r="2"/><circle cx="16" cy="9" r="2"/><circle cx="22" cy="12" r="2"/><circle cx="23" cy="18" r="2"/><circle cx="19" cy="23" r="2"/><path d="M16.54,2A14,14,0,0,0,2,16a4.82,4.82,0,0,0,6.09,4.65l1.12-.31A3,3,0,0,1,13,23.24V27a3,3,0,0,0,3,3A14,14,0,0,0,30,15.46,14.05,14.05,0,0,0,16.54,2Zm8.11,22.31A11.93,11.93,0,0,1,16,28a1,1,0,0,1-1-1V23.24a5,5,0,0,0-5-5,5.07,5.07,0,0,0-1.33.18l-1.12.31A2.82,2.82,0,0,1,4,16,12,12,0,0,1,16.47,4,12.18,12.18,0,0,1,28,15.53,11.89,11.89,0,0,1,24.65,24.32Z"/><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32"/></svg></div>',
        title: 'Change element color',
        action: {
          click: changeColor,
        },
      },
    };
  };
}

/**
 * A handler updating an elements color.
 */
function UpdateColorHandler() {
  this.execute = function(context) {
    context.oldColor = context.element.color;
    context.element.color = context.color;
    context.element.strokeColor = context.strokeColor;

    return context.element;
  };

  this.revert = function(context) {
    context.element.color = context.oldColor;

    return context.element;
  };
}

ColorPicker.$inject = [ 'eventBus', 'contextPad', 'commandStack' ];
