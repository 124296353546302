import { event } from "jquery";

export default function applyJsonModifications(modeler, eventBus, jsonData) {
  const elementRegistry = modeler.get('elementRegistry');
  const modeling = modeler.get('modeling');

  let imageStyle = '';

  jsonData.images.forEach((image) => {
    imageStyle += `
        .${image.title} {
          background-image: url(${image.url});
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 90%;
          height: 90%;
        }
      `;
  });

  Object.keys(jsonData.palette).forEach((paletteElement) => {
    if (!jsonData.palette[paletteElement].enabled) {
      let element = document.querySelector(`[data-action="${paletteElement}"]`);
      element.style.display = 'none';
    }
  });

  const style = document.createElement('style');
  style.innerHTML = imageStyle;
  document.head.appendChild(style);

  jsonData.elements.forEach((element) => {
    const diagramElement = elementRegistry.get(element.id);
    if (diagramElement) {

      // Define a cor
      modeling.setColor([ diagramElement ], {
        fill: element.fill,
        stroke: element.stroke,
      });

      if (element.url) diagramElement.url = element.url;

      // Define o texto
      modeling.updateLabel(diagramElement, element.text);

      diagramElement.width = element.width;
      diagramElement.height = element.height;

      if (diagramElement.label) {
        const labelPosition = {
          x: diagramElement.x + diagramElement.width / 2,
          y: diagramElement.y + diagramElement.height + 10,
          width: diagramElement.width / 3,
          height: 20,
        };
        modeling.updateLabel(diagramElement, element.text, labelPosition);
      }

      // Adiciona eventos
      /* Object.keys(element.events).forEach((eventType) => {
        const eventFunction = new Function(
          'e',
          'modeling',
          'diagramElement',
          `
            (async () => {
              if (${element.events[eventType] !== ''}) {
                e.preventDefault(); 
                let element = e.element;
                function updateText(text) {
                    modeling.updateLabel(diagramElement, text);
                }
                function updateImage(image) {
                    e.element.url = image;
                }
                if (e.element.id === "${element.id}") { 
                    ${element.events[eventType]}; 
                    modeling.updateProperties(e.element, {});
                } 
              }
            })();
            `,
        );
        eventBus.on(
          eventType,
          900,
          async (e) => eventFunction(e, modeling, diagramElement),
        );
      }); */
    }
  });
}
